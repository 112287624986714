div.cdscheduler {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: white;
    color: #6c757d;
    border: 1px solid #c0c0c0;
    font-weight: bold;
    line-height: 1.2;
    font-size: 14px;
}

.cdscheduler-resource-column {
    display: flex;
    flex-direction: column;
}

.cdscheduler-resource-column-top {
    flex: 0 0 80px;
}

.cdscheduler-resource-column-middle {
    overflow: hidden;
}

.cdscheduler-resource-column-bottom {
    flex: 0 0 0px;
}

.cdscheduler-grid-column {
    display: flex;
    flex-direction: column; 
    flex-grow: 1;
}

.cdscheduler-grid-column-times {
    flex: 0 0 40px;
    display: flex;
    overflow: hidden;
}

.cdscheduler-grid-column-times-corner {
    flex: 0 0 0px;
}

.cdscheduler-drag-shadow {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 10px;
    right: 10px;
    background-color: grey;
    display: none;
    opacity: 0.5;
    z-index: 999;
    pointer-events: none;
}

.csdscheduler-time-cell {
    flex: 0 0 40px;
    height: 40px;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 0; 
}

.csdscheduler-time-group-cell {
    height: 40px;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 0; 
}

.csdscheduler-grid-cell {
    flex: 0 0 40px;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    pointer-events: none;
}

.csdscheduler-grid-cell-non-working {
    background-color: #d4d4d4;
}

.csdscheduler-grid-row {
    display: flex; 
    pointer-events: none;
}

.csdscheduler-resource-cell {
    display: flex;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
}

.csdscheduler-time-highlight {
    background-color: #e6e6e6;
}

.csdscheduler-resource-highlight {
    background-color: #e6e6e6;
}

.csdscheduler-slot-highlight {
    background-color: #e6e6e6;
}

.csdscheduler-slot-selected {
    background-color: #b3b3b3;
}

.csdscheduler-event {
    height: 40px;
    background: linear-gradient(to bottom, #ffffff 0%, #fafafa);
    position: absolute;
    border: 1px solid #cccccc;
    font-size: 12px;
    color: rgb(51, 51, 51);
    /* overflow: hidden; */
}

.csdscheduler-event-selected {
    background: #dddddd !important;
}

.csdscheduler-event-resizer {
    width: 20px;
    height: 40px;
    position: absolute;
    top: 0;
    cursor: ew-resize;
}

.csdscheduler-event-travel-to {
    height: 40px;
    position: absolute;
    top: -6px;
    background: repeating-linear-gradient(
        45deg, 
        #62f5bd, 
        #62f5bd 10px, 
        #d8fff0 10px, 
        #d8fff0 20px
    );
    opacity: .5;
}

.csdscheduler-event-travel-from {
    height: 40px;
    position: absolute;
    top: -6px;
    background: repeating-linear-gradient(
        45deg, 
        #7d7dff,
        #7d7dff 10px, 
        #d8d8ff 10px, 
        #d8d8ff 20px
    );
    opacity: .5;
}

.cdscheduler-resource-column-top, 
.csdscheduler-time-group-cell, 
.csdscheduler-time-cell, 
.csdscheduler-resource-cell 
{
    border-right: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
}

#grid {
    overflow: auto;
    position: relative;
}

.rbt-input, .rbt-menu { width: 100%; min-width:300px; }