/* Availability */
.availMonth .day,
.availGrid .day {
  border: solid 1px #999;
  background: #fff;
  margin: 1px;
  text-align: left;
  color: black;
  overflow: hidden;
}
.availMonth .day.potential,
.availGrid .day.potential {
  background-color: #c9e0ab;
}
.availMonth .day.cancelledconfirmed,
.availGrid .day.cancelledconfirmed {
  background-color: #e1d940;
}
.availMonth .day.webbooking,
.availGrid .day.webbooking {
  background-color: #56a3ad;
}
.availMonth .day.flexiletbooking,
.availGrid .day.flexiletbooking {
  background-color: #d6c2de;
}
.availMonth .day.specialofferavailable,
.availGrid .day.specialofferavailable {
  border: 2px solid #af182c !important;
}
.availMonth .day.specialofferunavailable,
.availGrid .day.specialofferunavailable {
  border: 2px solid #1c64ac !important;
}
.availMonth .day.transferred,
.availGrid .day.transferred {
  background-color: #edf5e3;
}
.availMonth .day.owner,
.availGrid .day.owner {
  background-color: #7bafde;
}
.availMonth .day.invalid,
.availGrid .day.invalid {
  background-color: red;
}
.availMonth .day.unavailable,
.availGrid .day.unavailable {
  background: repeating-linear-gradient(45deg, #7bafde, #7bafde 4px, #b8d4ed 4px, #b8d4ed 7px);
}
.availMonth .day.unavailablenonlet,
.availGrid .day.unavailablenonlet {
  background: repeating-linear-gradient(45deg, #ff6600, #ff6600 4px, #ff944d 4px, #ff944d 7px);
}
.availMonth .day.cancelled,
.availGrid .day.cancelled {
  background: repeating-linear-gradient(45deg, #efeb98, #efeb98 4px, #fdfcf0 4px, #fdfcf0 7px);
}
.availMonth .day.cancelledpriority,
.availGrid .day.cancelledpriority {
  background: repeating-linear-gradient(45deg, #e1d940, #e1d940 4px, #b8b01d 4px, #b8b01d 7px);
}
.availMonth .day.confirmed,
.availGrid .day.confirmed {
  background-color: #66ac56;
}
.availMonth .day.provisional,
.availGrid .day.provisional {
  background: repeating-linear-gradient(45deg, #ffbf00, #ffbf00 4px, #ffd24d 4px, #ffd24d 7px);
}
.availMonth .day.provisionalaccepted,
.availGrid .day.provisionalaccepted {
  background: repeating-linear-gradient(45deg, #66ac56, #66ac56 4px, #94c589 4px, #94c589 7px);
}
.availMonth .day.fadee,
.availGrid .day.fadee {
  border: 1px solid #ddd;
  cursor: default;
  /*.potential {
        background-color: mix(@potential, #eee, 70%);
    }

    .owner {
        background-color: mix(@owner, #eee, 80%);
    }*/

}
.availMonth .day.fadee .afternoon.cod,
.availGrid .day.fadee .afternoon.cod {
  border-color: #aaa;
}
.availMonth .day.fadee .dayNum,
.availGrid .day.fadee .dayNum {
  color: #ccc;
}
.availMonth .day.today,
.availGrid .day.today {
  border: 2px solid #000;
}
.availMonth .day.today .dayNum,
.availGrid .day.today .dayNum {
  color: #000;
  font-weight: bold;
}
.availMonth .day .morning,
.availGrid .day .morning,
.availMonth .day .afternoon,
.availGrid .day .afternoon {
  position: absolute;
  width: 142%;
  height: 142%;
  top: 100%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transform-origin: 0 0;
  overflow: hidden;
}
.availMonth .day .morning.unavailable,
.availGrid .day .morning.unavailable,
.availMonth .day .afternoon.unavailable,
.availGrid .day .afternoon.unavailable {
  background: repeating-linear-gradient(90deg, #7bafde, #7bafde 4px, #b8d4ed 4px, #b8d4ed 7px);
}
.availMonth .day .morning.unavailablenonlet,
.availGrid .day .morning.unavailablenonlet,
.availMonth .day .afternoon.unavailablenonlet,
.availGrid .day .afternoon.unavailablenonlet {
  background: repeating-linear-gradient(90deg, #ff6600, #ff6600 4px, #ff944d 4px, #ff944d 7px);
}
.availMonth .day .morning.cancelled,
.availGrid .day .morning.cancelled,
.availMonth .day .afternoon.cancelled,
.availGrid .day .afternoon.cancelled {
  background: repeating-linear-gradient(90deg, #e1d940, #e1d940 4px, #efeb98 4px, #efeb98 7px);
}
.availMonth .day .morning.cancelledpriority,
.availGrid .day .morning.cancelledpriority,
.availMonth .day .afternoon.cancelledpriority,
.availGrid .day .afternoon.cancelledpriority {
  background: repeating-linear-gradient(90deg, #e1d940, #e1d940 4px, #b8b01d 4px, #b8b01d 7px);
}
.availMonth .day .morning.provisional,
.availGrid .day .morning.provisional,
.availMonth .day .afternoon.provisional,
.availGrid .day .afternoon.provisional {
  background: repeating-linear-gradient(90deg, #ffbf00, #ffbf00 4px, #ffd24d 4px, #ffd24d 7px);
}
.availMonth .day .morning.provisionalaccepted,
.availGrid .day .morning.provisionalaccepted,
.availMonth .day .afternoon.provisionalaccepted,
.availGrid .day .afternoon.provisionalaccepted {
  background: repeating-linear-gradient(45deg, #66ac56, #66ac56 4px, #94c589 4px, #94c589 7px);
}
.availMonth .day .warning,
.availGrid .day .warning {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  width: 30px;
  height: 12px;
  transform: rotate(45deg);
  transform-origin: 26px 20px;
}
.availMonth .day *,
.availGrid .day * {
  pointer-events: none;
}
.availMonth .day .morning,
.availGrid .day .morning {
  left: -100%;
  top: 0;
  transform-origin: 1px 0;
  overflow: hidden;
}
.availMonth .day .morning .cancelled,
.availGrid .day .morning .cancelled {
  width: 100%;
  height: 20%;
  transform: rotate(45deg);
  position: absolute;
  bottom: -13%;
}
.availMonth .day .afternoon.cod,
.availGrid .day .afternoon.cod {
  border: 1px solid #777;
}
.availMonth .day .afternoon .cancelled,
.availGrid .day .afternoon .cancelled {
  width: 100%;
  height: 20%;
  transform: rotate(45deg);
  position: absolute;
  top: -4%;
}
.availMonth .day .dayNum,
.availGrid .day .dayNum {
  padding: 3px;
  position: absolute;
  top: 0;
  background: transparent;
  font-size: 0.75em;
  line-height: 1em;
}
.availMonth .day .weekPrice,
.availGrid .day .weekPrice {
  position: absolute;
  bottom: 0;
  width: 100%;
  line-height: 1.2em;
  background-color: #777;
  color: #fff;
  text-align: right;
  padding-right: 1px;
  font-size: 0.75em;
}
.availMonth .day .changeDayFrom,
.availGrid .day .changeDayFrom {
  position: absolute;
  bottom: 3px;
  right: -3px;
}
.availMonth .day .changeDayFrom.fadee,
.availGrid .day .changeDayFrom.fadee {
  opacity: 0.4;
}
.availMonth .day .changeDayTo,
.availGrid .day .changeDayTo {
  position: absolute;
  bottom: 3px;
  left: 1px;
}
.availMonth .day .changeDayTo.fadee,
.availGrid .day .changeDayTo.fadee {
  opacity: 0.6;
}
.availMonth .day .changeDayConditions,
.availGrid .day .changeDayConditions {
  position: absolute;
  top: 0px;
  right: -2px;
}
.bookingType {
  padding: 2px 10px;
  display: block;
  text-align: center;
}
.potential {
  background-color: #c9e0ab;
}
.cancelledconfirmed {
  background-color: #e1d940;
}
.webbooking {
  background-color: #56a3ad;
}
.flexiletbooking {
  background-color: #d6c2de;
}
.specialofferavailable {
  border: 2px solid #af182c !important;
}
.specialofferunavailable {
  border: 2px solid #1c64ac !important;
}
.transferred {
  background-color: #edf5e3;
}
.owner {
  background-color: #7bafde;
}
.unavailable {
  background: repeating-linear-gradient(45deg, #7bafde, #7bafde 4px, #b8d4ed 4px, #b8d4ed 7px);
}
.unavailablenonlet {
  background: repeating-linear-gradient(45deg, #ff6600, #ff6600 4px, #ff944d 4px, #ff944d 7px);
}
.cancelled {
  background: repeating-linear-gradient(45deg, #efeb98, #efeb98 4px, #fdfcf0 4px, #fdfcf0 7px);
}
.cancelledpriority {
  background: repeating-linear-gradient(45deg, #e1d940, #e1d940 4px, #b8b01d 4px, #b8b01d 7px);
}
.confirmed {
  background-color: #66ac56;
}
.provisional {
  background: repeating-linear-gradient(45deg, #ffbf00, #ffbf00 4px, #ffd24d 4px, #ffd24d 7px);
}
.provisionalaccepted {
  background: repeating-linear-gradient(45deg, #66ac56, #66ac56 4px, #94c589 4px, #94c589 7px);
}
/* temporary rule to highlight 'invalid' bookings */
.invalid {
  background-color: red;
}
.availGridProperties {
  margin-top: 1em;
  margin-bottom: 1.5em;
}
.availGridProperty a {
  display: block;
  overflow: hidden;
  padding: 1em 0;
  margin-bottom: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#availability-report .availGridProperty {
  height: 24px;
}
.availGrid {
  margin: -1.9em 0 0;
  overflow: auto;
  white-space: nowrap;
}
.availGrid .dates {
  color: #999;
  text-transform: uppercase;
}
.availGrid .dates .month,
.availGrid .dates .date {
  display: inline-block;
}
.availGrid .dates .month {
  background: white;
  margin-right: -2px;
  padding-left: 2px;
  white-space: normal;
}
.availGrid .dates .monthName {
  margin-bottom: 4px;
  white-space: nowrap;
  width: 0;
}
.availGrid .dates .month .days {
  white-space: nowrap;
}
.availGrid .dates .month .date {
  color: #999;
  font-size: .75em;
  width: 48px;
}
.availGrid .row {
  height: 48px;
}
.availGrid .dayWrapper {
  display: inline-block;
}
.availGrid .day {
  border: 1px solid #ccc;
  display: inline-block;
  height: 44px;
  margin: 0 2px;
  overflow: hidden;
  position: relative;
  width: 44px;
}
.hasPopup {
  /* cursor: pointer; */
}
.availMonth {
  display: block;
  width: 292px;
  float: left;
  margin-bottom: 10px;
}
.availMonth .header {
  margin: 0 auto;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  padding: 0.5rem;
  text-transform: uppercase;
}
.availMonth .daysofweek .title {
  float: left;
  width: 40px;
  font-size: 0.8em;
  text-align: center;
  color: #999;
  text-transform: uppercase;
}
.availMonth .week {
  width: 292px;
  margin: 0 auto;
}
.availMonth .week .blankDay,
.availMonth .week .day {
  float: left;
  position: relative;
  width: 38px;
  height: 38px;
}
.availMonth .week .blankDay {
  width: 38px;
  margin: 1px;
}
.availMonth .week .blankDay span {
  display: none;
}
.availabilityHeader {
  margin-top: 10px;
}
.availabilityHeader .yearTitle {
  display: inherit;
  margin: 0 auto;
  width: 100px;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
}
.calendarWrapper,
.adminCalendarWrapper {
  width: 278px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .calendarWrapper {
    width: 584px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 992px) {
  .calendarWrapper {
    width: 876px;
    margin: 0 auto;
  }
  .adminCalendarWrapper {
    width: 590px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1260px) {
  .calendarWrapper {
    width: 1168px;
    margin: 0 auto;
  }
  .adminCalendarWrapper {
    width: 882px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1650px) {
  .calendarWrapper {
    width: 1460px;
    margin: 0 auto;
  }
  .adminCalendarWrapper {
    width: 1174px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1836px) {
  .calendarWrapper {
    width: 1752px;
    margin: 0 auto;
  }
  .adminCalendarWrapper {
    width: 1174px;
    margin: 0 auto;
  }
}